import React from "react"
import H2 from "../../core/H2"
import P from "../../core/P"
import Twitter from "../../../assets/images/store/Twitter.svg"
import LinkedIn from "../../../assets/images/store/LinkedIn.svg"

const SingleProfile = () => {
  return (
    <div className="lg:px-0 md:px-0 pt-2 pb-20 px-4">
      <div
        className="flex mx-auto lg:max-w-screen-lg md:max-w-screen-sm max-w-screen-sm justify-center items-center bg-white rounded-md"
        data-aos="fade-up"
      >
        <div className="grid grid-cols-6 lg:gap-6 md:gap-12 gap-6 lg:py-6 md:py-10 py-12">
          <div className="lg:col-span-2 md:col-span-6 col-span-6 flex flex-wrap content-center">
            <div className="flex flex-wrap content-center mx-auto relative">
              <div>
                <div className="self-center lg:w-52 lg:h-52 md:w-52 md:h-52 w-40 h-40">
                  {/*  add event organizer image*/}
                  <img
                    src={
                      "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/01/PrameshJ-Sir.png"
                    }
                    alt={"Pramesh Jain"}
                    width="180"
                    className="w-60 rounded-full border-4 border-gray-300"
                    height="180"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-4 md:col-span-6 col-span-6 p-4">
            {/*  add event organizer name and description */}
            <div>
              <H2
                title={"Pramesh Jain"}
                className="lg:text-left md:text-center text-center"
              />
              <P
                title={
                  `"As we look ahead, it's clear that the future of the tech world is constant evolution. One has to adapt technologies like AI, Blockchain, RPA, etc., to seize new business opportunities in the online space. At WebMob Technologies, we ensure to empower our clients with the best tech & SDLC methods for a successful business online."`
                }
                className="lg:text-left md:text-center text-center text-shark-400"
              />
            </div>
            <div className="flex lg:justify-start md:justify-center justify-center  items-center">
              {/*  add event organizer linkdin detail */}
              <a
                href={"https://www.linkedin.com/in/prameshjain/"}
                className=" py-5 inline-block text-white"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={LinkedIn}
                  alt="linkdin"
                  className="h-8"
                  loading="lazy"
                  width="32px"
                  height="32px"
                />
                <span className="sr-only">LinkedIn</span>
              </a>
              {/*  add event organizer twitter detail */}
              <a
                href={"https://twitter.com/webmobtech"}
                className=" py-5 inline-block  text-white ml-5"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Twitter}
                  alt="twitter"
                  className="h-8"
                  loading="lazy"
                  width="32px"
                  height="32px"
                />
                <span className="sr-only">Twitter</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleProfile
